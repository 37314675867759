import img1Src from 'assets/images/brand/1.webp'
import img2Src from 'assets/images/brand/2.webp'
import img3Src from 'assets/images/brand/3.webp'
import img4Src from 'assets/images/brand/4.webp'
import img5Src from 'assets/images/brand/5.webp'
import img6Src from 'assets/images/brand/6.webp'
import img7Src from 'assets/images/brand/7.webp'
import img8Src from 'assets/images/brand/8.webp'
import img9Src from 'assets/images/brand/9.webp'

export const brandData = [
  {
    image: img3Src
  },
  {
    image: img4Src
  },

  {
    image: img2Src
  },
  {
    image: img6Src
  },
  {
    image: img5Src
  },
  {
    image: img1Src
  },
  {
    image: img7Src
  },
  {
    image: img8Src
  },
  {
    image: img9Src
  },
]