import img1Src from 'assets/images/blog-post.webp'
import img2Src from 'assets/images/blog/6.webp'
import img3Src from 'assets/images/blog/5.webp'
import img4Src from 'assets/images/blog/4.webp'
import img5Src from 'assets/images/blog/3.webp'
import img6Src from 'assets/images/blog/2.webp'
import img7Src from 'assets/images/blog/1.webp'

export const blogData = [
  {
    id: 'about',
    image: img1Src,
    title: "Xcoub: Redefining DeFi Staking",
    text: "Xcoub is a cutting-edge decentralized finance (DeFi) platform designed to help users unlock the full earning potential of their digital assets through innovative staking solutions. Whether you're a crypto newcomer or a seasoned investor, Xcoub provides a secure, intuitive environment to stake a variety of tokens across multiple blockchain networks and maximize your returns—without relying on intermediaries.\n" +
      "Our platform supports a diverse range of blockchain ecosystems, including Ethereum, BNB Chain, Optimism, and more, with over 60 staking pools available across multiple tokens. Xcoub emphasizes flexibility and transparency, empowering users to diversify their investments and tailor strategies to achieve optimal rewards.\n" +
      "At Xcoub, we prioritize putting profits back into the hands of our users. An impressive 98% of all platform-generated fees and profits are redistributed to staking participants, while Xcoub retains just 2%. This ensures our community shares in the success of the platform.\n" +
      "To further enhance user experience, Xcoub has partnered with leading blockchain providers like Chainlink to integrate oracle solutions, and utilizes CoinMarketCap and CoinGecko APIs for real-time data accuracy. Our smart contracts are rigorously audited, ensuring your assets remain secure and protected.\n" +
      "Looking to the future, Xcoub is committed to expanding its capabilities by adding support for more blockchain networks, such as Manta, and launching innovative new staking pools. Backed by institutional investors and driven by a culture of innovation, Xcoub is poised to remain at the forefront of the DeFi staking revolution.\n" +
      "Join Xcoub today and take advantage of secure, high-yield staking opportunities to unlock the true potential of your crypto assets. ",
    time: '1 min read time'
  },
  {
    id: 'review-2',
    image: img7Src,
    title: "Xcoub Q2 2024 Review: Game-Changing Integrations, Partnerships, and Explosive TVL Growth",
    text: `<p>
As Q2 2024 wraps up, Xcoub has taken bold steps to redefine the decentralized finance (DeFi) landscape. From powerful integrations to groundbreaking projects, this quarter was all about innovation and growth. Here are the key highlights:
      <strong>1. Securing Data with <strong>IPFS</strong> Integration</strong><br/><br/>
      This quarter, we integrated the InterPlanetary File System (<strong>IPFS</strong>) into Xcoub, boosting data security and decentralization. With <strong>IPFS</strong>, platform data is stored and accessed more securely, delivering enhanced efficiency and reliability for users.<br/><br/>
      <strong>2. Strengthened Collaboration with Uniswap</strong><br/><br/>
      Expanding our partnership with Uniswap, Xcoub now features seamless asset swapping via Uniswap’s advanced swap function. The success of our UNI staking pool continues to empower users with rewarding opportunities, cementing Xcoub's position as a top-tier DeFi platform.
      <strong>3. Building the Future: DAO and Cross-Chain Innovations</strong><br/><br/>
      We are actively crafting DAO governance functionality, targeting a March-April 2025 launch. This milestone will give the community a voice in platform decisions, aligning Xcoub with the spirit of decentralized governance. <br/> Additionally, we’re exploring cross-chain bridge technology to make staking across multiple chains even more accessible. While a timeline isn’t set, it’s a key focus area for future development.<br/><br/>
      <strong>4. TVL Growth Soars by 167%</strong><br/><br/>
      Xcoub's Total Value Locked (TVL) skyrocketed to $80 million in Q2—an astounding 167% surge from Q1’s $30 million. This exponential growth showcases user confidence and increasing adoption of Xcoub’s multi-chain staking services.<br/><br/>
      <strong>Looking Ahead</strong><br/><br/>
      Xcoub remains dedicated to scaling the platform, forging strategic alliances, and delivering transformative solutions. Upcoming launches, including DAO governance and cross-chain capabilities, position us for even greater success in the DeFi space.<br/>
We extend our heartfelt thanks to our ever-supportive community. Stay connected for more updates as we shape the future of decentralized staking together.
    </p>`,
    date: 'July 5, 2024',
    time: '6 min read time'
  },
  {
    id: 'ipfs',
    image: img5Src,
    title: "Xcoub Integrates IPFS for Decentralized Data Storage Excellence",
    text: `<p>At Xcoub, innovation drives us forward as we build a robust staking platform that prioritizes security and efficiency. As part of this commitment, we’re thrilled to announce the integration of <strong>IPFS</strong> (InterPlanetary File System), a game-changing technology that enhances data integrity and decentralization across our platform. <br/><br/>
      <strong>What is <strong>IPFS</strong>?</strong><br/><br/>
     <strong>IPFS</strong> is a decentralized file storage system designed to move beyond traditional server-based storage. Unlike HTTP systems that rely on centralized servers, <strong>IPFS</strong> operates on a peer-to-peer network, storing data across multiple nodes and securing it with cryptographic hashes. Each file has a unique identifier, called a Content Identifier (CID), enabling tamper-proof, highly available, and efficient data storage and retrieval.<br/><br/>
Through <strong>IPFS</strong>, Xcoub ensures that platform data and user information are stored in a decentralized, resilient system that eliminates central points of failure. <strong>IPFS</strong>’s version control capabilities further enhance the system by keeping track of updates to files, ensuring users always have access to the latest, most reliable data.
<br/><br/>
      <strong>Why <strong>IPFS</strong> Matters for Xcoub Staking</strong><br/><br/>
      Integrating <strong>IPFS</strong> aligns perfectly with Xcoub’s vision of advancing the principles of decentralized finance (DeFi). This innovation delivers the following benefits to our users:<br/><br/>
      <ol>
        <li><strong>Enhanced Data Availability</strong>: <strong>IPFS</strong> replicates files across multiple nodes, ensuring staking pool information, rewards data, and platform updates remain accessible, even if some nodes go offline.
        </li>
        <li><strong>Superior Security</strong>: Decentralized storage reduces the risks of malicious attacks and data manipulation. Files secured with their unique CIDs are resistant to tampering, ensuring trust and integrity.
        </li>
        <li><strong>Cost Efficiency</strong>:  By storing large data off-chain and keeping only essential hashes on-chain, <strong>IPFS</strong> minimizes gas fees and storage costs, making staking more affordable for users.
        </li>
      </ol>
      <strong><strong>IPFS</strong> + Ethereum: A Perfect Match</strong><br/>
     Xcoub’s integration of <strong>IPFS</strong> with Ethereum smart contracts offers a powerful solution that combines decentralized data storage with on-chain functionality:<br/><br/>
      <ul>
        <li><strong>On-Chain Data Hashes</strong>: Files uploaded to <strong>IPFS</strong> are assigned a CID, which is stored in Ethereum smart contracts. This ensures data integrity while the full file resides on the <strong>IPFS</strong> network.
        </li>
        <li><strong>Seamless Data Access</strong>: Users access data stored on <strong>IPFS</strong> through the CID retrieved from Ethereum smart contracts. Even if certain nodes go offline, the data remains accessible via the peer-to-peer network.
        </li>
        <li><strong>Unmatched Security</strong>: The immutability of Ethereum smart contracts and the decentralized nature of <strong>IPFS</strong> guarantee that staking data and rewards remain transparent, trustworthy, and tamper-proof.
        </li>
      </ul>
      <br/><br/>
      <strong>Building the Future of Decentralized Data</strong><br/><br/>
      With <strong>IPFS</strong> integrated, Xcoub is setting new standards for decentralized staking platforms. This marks just the beginning of our journey into advanced, decentralized storage solutions. We are committed to pushing the boundaries of DeFi innovation to offer users unparalleled security, efficiency, and returns.<br/>
The combination of <strong>IPFS</strong> and Ethereum smart contracts signals a future where decentralized applications become more resilient and accessible. Xcoub is proud to lead the way, ensuring that our users benefit from cutting-edge technology and a secure staking experience.<br/>
Stay tuned as we continue to improve and evolve our platform to meet the needs of our thriving community. 🚀
    </p>`,
    date: 'May 28, 2024',
    time: '9 min read time'
  },
  {
    id: "review",
    image: img6Src,
    title: "Xcoub 2024 Q1 Review: Unprecedented Growth and Strategic Milestones",
    text: `<p>
As the first quarter of 2024 comes to a close, Xcoub is thrilled to reflect on a period of transformative growth and achievements. From impressive Total Value Locked (TVL) growth to groundbreaking partnerships, our platform has set a solid foundation for continued success in the decentralized finance (DeFi) space. Here’s an overview of our Q1 2024 highlights:
      <br/><br/>
      <strong>1. Remarkable TVL Growth: A 114% Increase</strong><br/><br/>
      This quarter, Xcoub achieved an extraordinary 114% increase in TVL, soaring from $14 million in Q4 2023 to $30 million by the end of Q1 2024. This surge underscores the growing trust in our platform and the robust staking opportunities we provide. The rise in TVL reflects both the expansion of our user base and heightened staking activity across multiple blockchain networks.
      <br/><br/>
      <strong>2. ARB Staking Pool Launch and $1.5 Million TVL</strong><br/><br/>
      One of our major milestones was the optimization and launch of the ARB staking pool. This pool has quickly gained traction, reaching a $1.5 million TVL in record time. The ARB pool exemplifies our ability to deliver seamless and rewarding staking experiences, cementing Xcoub’s position as a trusted platform for diverse staking solutions.
      <br/><br/>
      <strong>3. User and Wallet Growth</strong><br/><br/>
      Xcoub has witnessed a surge in user engagement, with independent active users surpassing 2,000 and connected wallets exceeding 4,500. This growth is a testament to our commitment to providing a secure, user-friendly platform that meets the needs of both novice and experienced crypto enthusiasts.
      <br/><br/>
      <strong>4. Strategic Partnerships Driving Innovation</strong><br/><br/>
      Partnerships have been pivotal in our success this quarter:
      <ul>
      <li>
      Chainlink Integration: By incorporating Chainlink’s oracle services, we’ve enhanced data accuracy and reliability for our users. This collaboration also enabled the successful launch of the LINK staking pool, broadening the range of assets users can stake on our platform.
</li><br/>
<li>
APIs from CoinMarketCap and CoinGecko: These partnerships provide real-time market data, empowering users to make informed staking decisions confidently.
</li>
</ul>
These alliances ensure that Xcoub remains at the forefront of DeFi innovation, delivering top-tier services to our growing community.
      <br/><br/>
      <strong>5. Expanding Staking Opportunities</strong><br/><br/>
      Continuing our mission to diversify staking options, we are deploying the APT staking smart contract and preparing to launch the Manta network staking pool in May 2024. These additions will offer our users even more opportunities to maximize their staking returns and diversify their crypto portfolios.
      <br/><br/>
      <strong>6. Commitment to Growth and Security</strong><br/><br/>
      Xcoub’s rapid expansion has been accompanied by significant investments in platform development and security. From infrastructure upgrades to rigorous security audits, we are dedicated to ensuring a secure, scalable, and user-friendly environment for all our stakeholders.
      <br/><br/>
      <strong>Looking Ahead</strong><br/><br/>
      Xcoub’s first-quarter achievements signal a promising future. The 114% TVL growth, launch of new staking pools, and strategic partnerships mark just the beginning of our journey in 2024. As we continue to push boundaries in the DeFi space, we remain committed to delivering innovative staking solutions, expanding blockchain integrations, and fostering a thriving community.<br/><br/>
Thank you to our growing community for your trust and support. Together, we are redefining what’s possible in decentralized finance.<br/><br/>
Stay tuned as we build on this momentum in the months ahead! 🚀
    </p>`,
    date: 'April 7, 2024',
    time: '7 min read time'
  },
  {
    id: "partnerships",
    image: img4Src,
    title: "Xcoub Enhances Platform Stability Through Partnerships with CoinGecko and CoinMarketCap",
    text: `<p>
     At Xcoub, we are dedicated to delivering the most reliable and seamless staking experience for our users. To achieve this, we actively seek collaborations with industry leaders who share our vision for innovation and trust in decentralized finance (DeFi). Among our most significant partnerships are those with CoinGecko and CoinMarketCap—two of the most respected platforms for cryptocurrency data aggregation.
      <br/><br/>
      <strong>Why These Partnerships Matter</strong>
      <br/><br/>
      As a DeFi staking platform, Xcoub’s success depends on providing users with accurate, real-time data for token prices, market trends, and staking opportunities. CoinGecko and CoinMarketCap lead the industry in aggregating and delivering comprehensive, up-to-the-minute information, making them ideal partners for our platform.<br/><br/>
Through these partnerships, Xcoub has integrated CoinGecko and CoinMarketCap APIs to ensure our users can access stable and consistent data. This integration supports informed decision-making, allowing users to track token values, monitor staking pool metrics, and respond effectively to market trends.<br/><br/>
      <br/><br/>
      <strong>Real-Time Market Data for Smarter Staking</strong>
      <br/><br/>
      Trustworthy and real-time data are the cornerstones of a successful staking strategy. By leveraging CoinGecko and CoinMarketCap’s APIs, Xcoub delivers accurate, live price feeds to its users. This ensures token prices and market movements are always up-to-date, empowering users to optimize their staking strategies with confidence.<br/>
For example, the value of staked assets can shift due to market fluctuations. With our enhanced data capabilities, users can make timely adjustments to their staking positions, maximizing returns and minimizing risks. This transparency solidifies Xcoub’s reputation as a reliable platform for long-term staking.
      <br/><br/>
      <strong>Ensuring Stability and Consistency</strong>
      <br/><br/>
      In the fast-paced world of cryptocurrency, data stability is critical. Delays or inaccuracies in data can lead to missed opportunities or misinformed decisions. By partnering with CoinGecko and CoinMarketCap, Xcoub ensures its users receive consistent, reliable information—even in volatile market conditions. These collaborations underpin our commitment to providing a stable staking environment where users can focus on growth without concerns about data reliability.
      <br/><br/>
      <strong>Looking Ahead</strong>
      <br/><br/>
      Xcoub is committed to leveraging its partnerships with CoinGecko and CoinMarketCap to continually improve the staking experience for our community. These collaborations are integral to our mission of delivering a secure, transparent, and user-centric DeFi platform.
As we expand, we aim to enhance our offerings further, introducing innovative tools and features that empower our users to make well-informed decisions. By working closely with industry-leading partners, Xcoub is not only building a more robust platform but also positioning itself for sustained success in the evolving DeFi landscape.
Thank you to our users for trusting Xcoub as your preferred staking platform. Together, we’re shaping the future of decentralized finance.
    </p>`,
    date: 'February 7, 2024',
    time: '7 min read time'
  },
  {
    id: "partners-chainlink",
    image: img3Src,
    title: "Xcoub Partners with Chainlink to Enhance Staking and Launches LINK Staking Pools",
    text: `<p>
      At Xcoub, our mission is to deliver a secure, user-focused staking platform that maximizes value for our community. To achieve this, we’ve partnered with Chainlink, the industry’s leading decentralized oracle provider. This collaboration not only enhances data reliability across our platform but also introduces a major new feature: the launch of LINK staking pools on Xcoub.
      <br/><br/>
      <strong>Why Chainlink?</strong>
      <br/><br/>
      Chainlink is globally recognized as the most trusted decentralized oracle network, providing secure, real-time, and tamper-proof data feeds to smart contracts. By integrating Chainlink’s oracle service, Xcoub ensures accurate and reliable data for staking, enabling users to make well-informed decisions with confidence.<br/><br/>
Chainlink’s robust price feeds strengthen Xcoub’s platform security by reducing the risk of data manipulation and inaccuracies. This partnership fortifies Xcoub’s foundation, ensuring that users can trust the data and rewards they receive.
      <br/><br/>
      <strong>Introducing LINK Staking Pools</strong>
      <br/><br/>
      As part of this strategic collaboration, Xcoub is excited to announce the launch of LINK staking pools. These pools offer LINK holders new opportunities to earn rewards, supported by Chainlink’s reliable data feeds. We’ve designed four distinct LINK staking pools to meet the diverse needs of our community:
      <br/><br/>
      <ul>
        <li><strong>14-Day Staking Pool:</strong></li>
        <li class='secondLi'><strong>Limit</strong>: 500 - 2000 LINK</li>
        <li class='secondLi'>Perfect for users seeking short-term gains with minimal commitment.</li>
        <li><strong>30-Day Staking Pool:</strong></li>
        <li class='secondLi'><strong>Limit</strong>: 2000 - 5000 LINK</li>
        <li class='secondLi'>A solid choice for those looking to lock their tokens for a month and enjoy competitive rewards.
        </li>
        <li><strong>60-Day Staking Pool:</strong></li>
        <li class='secondLi'><strong>Limit</strong>: 3000 - 10,000 LINK</li>
        <li class='secondLi'>Ideal for users balancing flexibility with higher returns.
        </li>
        <li><strong>90-Day Staking Pool:</strong></li>
        <li class='secondLi'><strong>Limit</strong>: 5000 - 30,000 LINK</li>
        <li class='secondLi'>Designed for long-term stakers aiming for maximum rewards with larger holdings.
        </li>
      </ul>
      <br/><br/>
      These pools provide flexibility, allowing users to choose the duration and commitment that best suits their goals. Each pool is capped to ensure fair distribution of rewards and a balanced staking ecosystem.
      <br/><br/>
      <strong>The Benefits of Staking LINK on Xcoub</strong>
      <br/><br/>
      By staking LINK on Xcoub, users gain access to the combined power of Chainlink’s secure oracle services and Xcoub’s reliable staking infrastructure. The integration ensures that staking rewards are based on real-world, accurate data, providing transparency and trust for all participants.<br/><br/>
With four LINK staking pools available, users can select the option that aligns with their strategy—whether they prioritize short-term flexibility or long-term gains. These staking opportunities empower users to maximize their LINK holdings while enjoying a secure and seamless experience.
      <br/><br/>
      <strong>Looking Ahead</strong>
      <br/><br/>
      This partnership with Chainlink represents a major milestone for Xcoub as we continue to innovate and expand our platform. The integration of Chainlink’s trusted oracle network and the successful launch of LINK staking pools demonstrate our commitment to enhancing the staking experience for our users.<br/><br/>
We’re excited to see our community engage with these new opportunities and look forward to future collaborations with Chainlink to bring even more groundbreaking features to Xcoub. Together, we’re shaping the future of decentralized staking.
    </p>`,
    date: 'February 25, 2024',
    time: '8 min read time'
  },
  {
    id: "quarter-review",
    image: img2Src,
    title: "Xcoub First Quarter Review: A Strong Foundation for a Bright Future",
    text: `<p>As we wrap up our first quarter, Xcoub reflects proudly on the significant milestones and achievements that have set the stage for our growth within the DeFi staking ecosystem. From securing institutional investments to expanding support across multiple chains, we are steadfast in our commitment to delivering a secure and rewarding staking experience for our users. Here’s a look back at our remarkable progress over the past few months.
      <br/><br/>
      <strong>1. Institutional Investment and Early Growth</strong>
      Xcoub has garnered robust support from institutional investors, raising a total of $1 million in funding from five different institutions. This critical investment has fueled our ability to enhance the platform, introduce innovative features, and establish a strong foothold in the decentralized finance (DeFi) sector. The backing of these institutions reflects their confidence in Xcoub's mission and long-term potential, equipping us with the resources needed to drive sustained innovation.
      <br/><br/>
      <strong>2. Expanding Token and Staking Pool Offerings</strong>
      In just one quarter, Xcoub has significantly broadened its token and staking pool ecosystem. On the Ethereum chain, we now support seven tokens across more than 30 staking pools, providing a wealth of opportunities for Ethereum-based asset holders to maximize their returns.<br/><br/>
Our growth on the BNB Chain has been equally impressive. Xcoub currently supports five tokens with over 20 staking pools, giving users greater flexibility and more options to earn consistent rewards in a secure environment.
      <br/><br/>
      <strong>3.  Outstanding TVL Growth on the BNB Chain</strong>
      The Total Value Locked (TVL) on the BNB Chain has skyrocketed. Early in the quarter, we surpassed the $2 million TVL milestone, and shortly after, we achieved an impressive $12 million in TVL on the BNB Chain. This rapid growth underscores the trust our users place in Xcoub and highlights the increasing popularity of staking on our platform.
      <br/><br/>
      <strong>4. ARB Chain Expansion: Unlocking New Opportunities</strong>
      Staying true to our mission of supporting diverse blockchain networks, Xcoub has deployed the ARB Chain smart contract. This pivotal step will soon allow users to stake ARB tokens, unlocking new earning opportunities. The development of the ARB Staking Pool is nearing completion, and its launch will further expand Xcoub’s capabilities and staking options.
      <br/><br/>
      <strong>5. Commitment to Security</strong>
      At Xcoub, security remains our top priority. We are proud to announce the successful completion of a comprehensive security audit, confirming that Xcoub meets the highest industry standards for safety. This audit provides users with the assurance that their assets are well-protected, and we remain committed to continuously improving our security protocols as we grow.
      <br/><br/>
      <strong>Looking Ahead</strong>
      The first quarter has been a period of remarkable growth and innovation for Xcoub, but we’re just getting started. With strong institutional backing, an ever-expanding range of tokens and staking pools, and ongoing success across multiple chains, we are positioned for even greater accomplishments in the months to come.<br/><br/>
As we prepare to launch the ARB Staking Pool and continue expanding our ecosystem, we extend our gratitude to our community for their unwavering trust and support. Xcoub remains dedicated to providing a secure, intuitive, and profitable staking experience for all.<br/><br/>
Stay tuned for more exciting developments as we continue building a platform that empowers our users and redefines staking in the DeFi space.
    </p>`,
    date: 'January 5, 2024',
    time: '5 min read time'
  },
]