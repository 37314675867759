import React from "react"
import "./Code.scss"
import srcCodeImage from 'assets/images/code.png'
import img1Src from "../../assets/images/swap.webp";
import {SimpleButton} from "../SimpleButton";
import {hrefs, routes} from "../../utils";

export const Code = (): JSX.Element => {
  return (
    <div className="code">
      <div data-aos="fade-right" className='code-left'>
        <h3>Apply algorithms for best Rewards.</h3>
        <p>
          Xcoub leverages advanced algorithms to enhance your staking rewards, dynamically analyzing market trends and optimizing strategies in real time.
        </p>
        <img src={img1Src} alt='swap'/>
      </div>
      <div data-aos="fade-left" className='code-right'>
        <img src={srcCodeImage} alt='code'/>
        <h3>Secure</h3>
        <p data-aos="fade-up-right" className="swap-home-desc">
          Security is paramount for DeFi and embedded in Xcoub’s DNA.
          <br/>
          <br/>
          Securely execute interchain strategies with noncustodial <br/>vaults - your keys, your crypto.
          <br/>
          <br/>
          Xcoub’s software is independently audited by a reputable third-party firm.
        </p>
        <SimpleButton text='Audit' href={hrefs.audit}/>
      </div>
    </div>
  )
}
