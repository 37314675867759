import React from 'react';
import ReactDOM from 'react-dom/client';
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { WagmiProvider} from "wagmi";
import { mainnet, bsc, bscTestnet, optimism, avalanche, manta, fantom, base, polygon, arbitrum, arbitrumNova } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// 2. Create wagmiConfig
const metadata = {
  name: 'Xcoub',
  description: 'Xcoub',
  url: 'https://xcoub.com/', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

export const config = defaultWagmiConfig({
  chains: [mainnet, bsc, bscTestnet, optimism, avalanche, manta, fantom, base, polygon, arbitrum, arbitrumNova],
  projectId: 'b6e55c03f74a93a55426c18e4cf76a27',
  metadata,
})

const queryClient = new QueryClient();

AOS.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// 3. Create modal
createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId: 'b6e55c03f74a93a55426c18e4cf76a27',
  enableAnalytics: true
})
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
          <App />
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
