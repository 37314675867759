import React from "react"
import "./Features.scss"
import { routes } from "../../utils"
import { Link, useSearchParams } from "react-router-dom"
import img1 from "assets/icons/features/1.svg"
import img2 from "assets/icons/features/2.svg"
import img3 from "assets/icons/features/3.svg"
import img4 from "assets/icons/features/4.svg"

export const Features = (): JSX.Element => {
  const [search] = useSearchParams()

  const blocks = [
    {
      img: img1,
      title: "Multi-Chain \nLiquidity Provision",
      desc: "Contribute to the growth of the crypto ecosystem by providing liquidity across multiple blockchains and earning attractive interest on your supplied assets.",
      btnText: "View Supported Chains & Assets",
      route: `${routes.farms}?${search.toString()}`,
    },
    {
      img: img2,
      title: "Flexible Staking \nOptions",
      desc: "Tailor your staking experience with a wide variety of supported cryptocurrencies, allowing you to maximize your earnings potential based on your unique preferences and risk tolerance.",
      btnText: "Discover Staking Opportunities",
      route: `${routes.pool}?${search.toString()}`,
    },
    {
      img: img3,
      title: "Discover Staking \nOpportunities",
      desc: "Experience peace of mind with our safe and reliable decentralized smart contracts, designed to ensure the security and autonomy of your staking transactions.",
      btnText: "Browse Contract List",
      route: `${routes.pool}?${search.toString()}`,
    },
    {
      img: img4,
      title: "Rewarding Referral \nProgram",
      desc: "Share the benefits of Xcoub with friends and earn valuable rewards! Invite your network to join our platform and receive generous referral bonuses as they stake and grow their crypto assets.",
      btnText: "View Referral Program Details",
      route: `${routes.referral}?${search.toString()}`,
    },
  ]
  return (
    <div className="features">
      <div data-aos="fade-right">
        <h3 className="features-title">Empowering Features</h3>
        <p className="features-desc">Enhance Your Multi-Chain Staking Experience with
          Xcoub</p>
      </div>
      <div className="features-content" data-aos="fade-left">
        {blocks.map((block, index) => (
          <div key={index} className="features-content-item">
            <div className='features-content-item-header'>
              <div className='features-content-item-header-icon'>
                <img src={block.img} alt='feature' />
              </div>
              <div className="features-content-item-title">{block.title}</div>
            </div>
            <div className='features-content-item-right'>
              <div className="features-content-item-desc">{block.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
