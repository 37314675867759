export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://x.com/Xcoub_Official",
  youtube: "",
  discord: "",
  scan: "",
  telegram: "https://t.me/Xcoub_Official",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
  audit: "https://shield.quillai.network/Xcoub/shareablelink/8fad25f99f49242e5ab578412cb7248bd84177bb75017f658be765a51f84b97c?type=1&projectID=4480"
}
