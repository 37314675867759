import React from "react"

import img1 from "assets/images/news-1.png"
import img2 from "assets/images/news-2.png"
import img3 from "assets/images/news-3.png"
import "./NewsHome.scss"
import {SimpleButton} from "../SimpleButton";
import {routes} from "../../utils";

export const NewsHome = (): JSX.Element => {
  const blocks = [
    {
      title: "Xcoub Secures $3 Million in Seed Funding to Revolutionize Staking",
      desc: "Wellington, New Zealand, 10th Dec 2024 – Xcoub has reached a significant milestone, raising $3 million in seed funding to reshape the decentralized finance (DeFi) landscape. This substantial investment demonstrates strong confidence from key investors, solidifying Xcoub’s position as an innovator in the ever-evolving DeFi ecosystem.",
      link: "https://www.digitaljournal.com/pr/news/binary-news-network/xcoub-secures-3-million-seed-1481499975.html#google_vignette",
      source: "Digital Journal",
      icon: img2,
    },
    {
      title: "Xcoub Secures $3 Million in Seed Funding to Revolutionize DeFi Staking",
      desc: "Xcoub, a rising star in the decentralized finance (DeFi) \n" +
        "space, has successfully closed a $3 million seed funding round. This \n" +
        "achievement underscores growing investor confidence in Xcoub's ability to \n" +
        "innovate and lead in the rapidly evolving DeFi staking market. The fresh \n" +
        "capital will empower Xcoub to scale operations, enhance its platform, and \n" +
        "deliver groundbreaking solutions for the DeFi ecosystem.",
      icon: img1,
      link: "https://app.binance.com/uni-qr/cart/17337399273882?r=22537208&l=en&uco=cZfRmUfvvPMs9bE0-EKROQ&uc=app_square_share_link&us=copylink",
      source: 'Binance',
    },
    {
      title: "Xcoub: Revolutionizing Staking Solutions",
      desc: "Xcoub is making its mark in the decentralized finance (DeFi) world by offering cutting-edge staking services that combine multi-chain support, advanced technology, and a seamless user experience. The platform is rapidly becoming a preferred choice for users globally who demand secure and rewarding staking opportunities.\n",
      link: "https://coinmarketcap.com/community/articles/6756f2f0b000c7282aff38c0/",
      source: 'CoinMarketCap',
      icon: img3,
    },
  ]
  return (
    <div className="news-home">
      <h3 data-aos="fade-up" className="news-home-title">In the News</h3>
      <div data-aos="fade-up" className="news-home-content">
        {blocks.map((block, index) => (
          <a href={block.link} target='_blank' rel='noreferrer noopener' key={index} className="news-home-content-item">
            <div className="news-home-content-item-icon">
              <img alt="news-home" src={block.icon}/>
            </div>
            <div className="news-home-content-item-title">{block.title}</div>
            <div className="news-home-content-item-desc">{block.desc}</div>
            <div className="news-home-content-item-footer">From <b>{block.source}</b></div>
          </a>
        ))}
      </div>
      <div className='news-home-btn'>
        <SimpleButton text='More Crypto News' href={routes.news} variant='colored'/>
      </div>
    </div>
  )
}
