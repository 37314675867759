import React, { useEffect, useState } from "react"
import { Header, Footer } from "components"

import "../Terms/Terms.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"

export const Trademark = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="terms" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      <Header />
      <div className='news-prices'>
        {
          // @ts-ignore
          (<gecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,dydx-chain" currency="usd"
                                            dark-mode="true"
                                                locale="en"/>)
        }
      </div>

      <div className="terms-content">
        <h1 className="terms-content-title">Xcoub Trademark Guidelines</h1>
        <p className="terms-content-desc">
          Xcoub distributed the open source Xcoub protocol (versions 1, 2, and 3) and distributes other
          software. While our code is in part available to download, review, and improve under open-source and
          source-available software licenses, none of our licenses include a license to use our trademarks. Proper use
          of our trademarks is essential to inform users whether or not Xcoub stands behind a product or service.
          When using Xcoub’ trademarks, you must comply with these Xcoub Trademark Guidelines. Just like
          other projects that develop open source software, we must enforce our trademark rights to protect our users.
          <br/>
          This policy covers all of our trademarks and services marks, whether they are registered or not, including,
          among others:
          <br/>
          1. The trademarks and service marks: Xcoub®, and Xcoub LABS™ (“Xcoub wordmarks”).
          <br/>
          2. Xcoub logos.
          <br/>
          If you want to report misuse of a Xcoubs Labs trademark, please contact us at <a
          href='mailto:support@xcoub.com'>support@xcoub.com</a>
        </p>
        <h3 className="terms-content-subtitle">Acceptable Uses</h3>
        <p className="terms-content-desc">
          You may do the following without receiving specific permission from Xcoub:
          <ul>
            <li>
              Use Xcoub wordmarks in text to truthfully refer to and/or link to unmodified Xcoub smart contracts,
              protocols, interfaces, programs, products, services and technologies (“Xcoub software”).
            </li>
            <li>
              Use the Xcoub wordmarks to truthfully describe modified versions of Xcoub software that you may create or make available. For example, you may say “This software is derived from Xcoub software.” or “This service uses software derived from Xcoub software.”
            </li>
            <li>
              Use the Xcoub logos in software or aggregators that integrate with Xcoub software to truthfully refer to, and, where possible, link to the applicable Xcoub software hosted on the Ethereum blockchain.
            </li>
            <li>
              Use Xcoub wordmarks to clearly signal to users that there is no affiliation with or endorsement by Xcoub.
            </li>
            <li>
              Follow the terms of the open source licenses for Xcoub software.
            </li>
          </ul>
        </p>
        <h3 className="terms-content-subtitle">When allowed, how can I use a Xcoub trademark?</h3>
        <p className="terms-content-desc">
          <ul>
            <li>
              You must include a trademark attribution notice at the first or most prominent mention of the mark on a webpage, document or documentation, such as: “[Xcoub trademark] is a trademark of Xcoub.”
            </li>
            <li>
              You may not change the Xcoub logos except to scale them. If you use the Xcoub logos, give them adequate spacing from the other elements on the webpage or document to ensure legibility and reduce the likelihood of confusion.
            </li>
            <li>
              You must always use the wordmarks in their exact form and with the correct spelling, neither abbreviated, hyphenated, nor combined with any other word or words.
            </li>
            <li>
              You must always use the Xcoub wordmarks only as an adjective, never as a noun or verb, or in plural or possessive forms.
            </li>
            <li>
              Instead, use the generic term for the Xcoub product or service following the trademark.
            </li>
            <li>
              For example: Xcoub protocol, Xcoub interface, Sybil website.
            </li>
          </ul>
        </p>
        <h3 className="terms-content-subtitle">All other uses of a Xcoub trademark require our prior written permission.</h3>
        <p className="terms-content-desc">
          Contact us at support@xcoub.com for more information.
        </p>
        <h3 className="terms-content-subtitle">Unacceptable Uses</h3>
        <p className="terms-content-desc">
          Some specific things you should not do include:
          <ul>
            <li>
              Don’t use our trademarks in anything dishonest or fraudulent.
            </li>
            <li>
              Our name is not your name. Don’t use Xcoub trademarks in the name of your smart contract, interface, business, product, service, app, domain name, publication, or other offering.
            </li>
            <li>
              Don’t use our name for your products. Don’t apply Xcoub trademarks to any product (e.g., a mobile app), unless such use is limited to a truthful and descriptive reference (e.g., “Mobile App for trading on Xcoub interface”).
            </li>
            <li>
              Don’t overemphasize our mark. Don’t display Xcoub trademarks more prominently than your product, service, or company name.
            </li>
            <li>
              Don’t create or use confusingly similar names. Don’t use Xcoub trademarks, company names, slogans, domain names, or designs that are confusingly similar to Xcoub trademarks, particularly in the name of your smart contract, interface, business, product, service, app, domain name, publication, or other offering.
            </li>
            <li>Don’t imply our sponsorship of your products. Don’t use Xcoub trademarks in a way that incorrectly implies affiliation with, sponsorship, endorsement, or approval by Xcoub of your products or services. For example, please do not name your project compatible with Xcoub software Xcoub-[Something] or [Something]-swap.</li>
            <li>Don’t imply our sponsorship of your activities. Don’t use Xcoub trademarks, or confusingly similar trademarks on social media accounts in a way that might suggest affiliation with Xcoub or Xcoub software; except if you’ve received prior permission from Xcoub. For example, you cannot name your account, page, or community “Xcoub Protocol” or “Xcoub Team.” However, it would be acceptable to name your account, page, or community “Fans of Xcoub” or “Information about Xcoub”.</li>
            <li>Don’t create swag with our marks. Don’t use Xcoub trademarks on merchandise for sale (e.g., selling t-shirts, mugs, etc.) unless you have permission from Xcoub.</li>
            <li>Don’t change or combine our marks. Don’t modify Xcoub trademarks, abbreviate them, or combine them with any other symbols, words, or images, or incorporate them into a tagline or slogan.</li>
          </ul>
        </p>
        <h3 className="terms-content-subtitle">Others’ Trademarks</h3>
        <p className="terms-content-desc">
          A note on others’ trademarks: Xcoub manages one interface (among many) for accessing the Xcoub protocol, which it does NOT control. Without the involvement of Xcoub, a third-party developer can use the Ethereum protocol to create a token that may implicate others’ trademarks or other rights and add that token to the Xcoub protocol. Xcoub cannot prevent or block any actions related to the Xcoub protocol, however, if Xcoub becomes aware of trademark misuse allegations, we will work with trademark owners to review the allegations and may remove content from the Xcoub interface.
        </p>
      </div>
      <Footer/>
    </div>
  )
}
